import React, { Fragment, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Mapping, Grouping, MapRoute } from "react-router-mapping";
import cookie from "react-cookies";
import moment from "moment";

//Loading
import LoadingSpin from './pages/LoadingSpin';
import { t } from "ttag";

//error
const Error = lazy(() => import("./templates/404"));
// Pages
const Login = lazy(() => import("./templates/Login"));
const FilaDeFaturamento = lazy(() => import("./pages/FilaDeFaturamento"));
const ContasTitularidade = lazy(() =>
  import("./pages/FilaDeFaturamento/ContasTitularidade")
);
const StepDeServico = lazy(() => import("./pages/StepDeServico"));
const NovoFaturamento = lazy(() => import("./pages/NovoFaturamento"));

//billing
const GrupoDeServico = lazy(() => import("./pages/Billing/GrupoDeServicos"));
const Operadora = lazy(() => import("./pages/Billing/Operadora"));
const PerfilCliente = lazy(() => import("./pages/Billing/PerfilCliente"));
const ServicoOperadora = lazy(() => import("./pages/Billing/ServicoOperadora"));
const PreFatura = lazy(() => import("./pages/Billing/PreFatura"));
const FaturaImposto = lazy(() => import("./pages/Billing/FaturaImposto"));
const FaturaConsumo = lazy(() => import("./pages/Billing/FaturaConsumo"));

const Manutencao = lazy(() => import("./pages/Billing/Faturamento/Manutencao"));
const FaturamentoAutomatico = lazy(() => import("./pages/Billing/Faturamento/FaturamentoAutomatico"));

const RegraFaturamento = lazy(() => import("./pages/Billing/FatServicos/RegraFaturamento"));
const CatalogoTarifa = lazy(() => import("./pages/Billing/FatServicos/CatalogoTarifa"));
const FaturaDetalheServico = lazy(() => import("./pages/Billing/FatServicos/FaturaDetalheServico"));
const ClienteServicoCNPJ = lazy(() => import("./pages/Billing/FatServicos/ClienteServicoCNPJ"));

const DetalhamentoAtivoArquivo = lazy(() => import("./pages/Billing/Detalhamento/AtivoDetalheArquivo"));
const DetalhamentoAtivos = lazy(() => import("./pages/Billing/Detalhamento/AtivoDetalhe"));

// contrato
const Contrato = lazy(() => import("./pages/Financeiro/Contrato"));
const DetalhesContrato = lazy(() => import("./pages/Financeiro/Contrato/DetalhesContrato"));

// contrato - conta
const ContratoContas = lazy(() => import("./pages/Financeiro/Contrato/Conta"));
const DetalhesConta = lazy(() => import("./pages/Financeiro/Contrato/Conta/DetalhesConta"));
const CadastroConta = lazy(() => import("./pages/Financeiro/Contrato/Conta/CadastroConta"));

// contrato - conta - fatura
const Fatura = lazy(() => import("./pages/Financeiro/Contrato/Fatura"));
const DetalhesFatura = lazy(() => import("./pages/Financeiro/Contrato/Fatura/DetalhesFatura"));
const CadastroFatura = lazy(() => import("./pages/Financeiro/Contrato/Fatura/CadastroFatura"));

const LogsNotificacao = lazy(() => import("./pages/LogsNotificacao"));


// tipo ativo
const TipoAtivo = lazy(() => import("./pages/Inventario/TipoAtivo"));
const TipoAtivoCriarEditar = lazy(() => import("./pages/Inventario/TipoAtivo/CriarEditar"));
const TipoAtivoCriarEditar2 = lazy(() => import("./pages/Inventario/TipoAtivo/CriarEditar2"));

// ativo
const Ativo = lazy(() => import("./pages/Inventario/Ativo"));
const DetalhesAtivo = lazy(() => import("./pages/Inventario/Ativo/DetalhesAtivo"));
const AtivoCriarEditar = lazy(() => import("./pages/Inventario/Ativo/CriarEditar"));


const RelacionamentosAtivo = lazy(() =>
  import("./pages/Inventario/RelacionamentosAtivoAtivo")
);

const Backoffice = lazy(() => import("./pages/Backoffice"))

const GenericoExcecaoRegra = lazy(() => import("./pages/GenericoExcecaoRegra"))

//relatorios
const Relatorios = lazy(() => import("./pages/Relatorios"));
const NovoRelatorio = lazy(() => import("./pages/Relatorios/CriarEditar"));
const EditarRelatorio = lazy(() => import("./pages/Relatorios/CriarEditar"));
const DetalhesRelatorio = lazy(() => import("./pages/Relatorios/DetalhesRelatorio"));

//Catalogo
const Catalogo = lazy(() => import("./pages/Catalogo"));
const CadastroPerfil = lazy(() => import("./pages/Catalogo/CadastroPerfil"));
const EditarPerfil = lazy(() => import("./pages/Catalogo/EditarPerfil"));
const DetalhesPerfil = lazy(() => import("./pages/Catalogo/DetalhesPerfil"));

//Usuários
const ListaUsuarios = lazy(() => import("./pages/Inventario/Usuarios"));
const CadastroUsuario = lazy(() => import("./pages/Inventario/Usuarios/Cadastro"));
const DetalhesUsuario = lazy(() => import("./pages/Inventario/Usuarios/Detalhes"));

// Dashboard
const DashClientes = lazy(() => import("./pages/Dashboard/Clientes"));
const DashboardAtivos = lazy(() => import("./pages/Dashboard/Ativos"));
const NewDashboard = lazy(() => import("./pages/Dashboard/NewDashboard"));
const DetalhesDashboard = lazy(() => import("./pages/Dashboard/Ativos/Detalhes"));
const ControleContas = lazy(() => import("./pages/Dashboard/ControleContas"))

// Configurações
const Webhooks = lazy(() => import("./pages/Configuracoes/Webhooks"));
const WebhooksDetalhes = lazy(() => import("./pages/Configuracoes/Webhooks/Detalhes"));
const AtivoPermissionamento = lazy(() => import("./pages/Configuracoes/Permissionamentos/Ativo"));
const TipoAtivoPermissionamento = lazy(() => import("./pages/Configuracoes/Permissionamentos/TipoAtivo"));
const FiltroCampoAtivos = lazy(() => import("./pages/Configuracoes/FiltroCampoAtivos"));
const PropsTipoAtivoPermissionamento = lazy(() => import("./pages/Configuracoes/Permissionamentos/PropsTipoAtivo"));
const ExtensoesEstoqueMinimo = lazy(() => import("./pages/Configuracoes/Extensoes/EstoqueMinimo"));
const ExtensoesEstoqueMinimoTodosAtivos = lazy(() => import("./pages/Configuracoes/Extensoes/EstoqueMinimoTodosAtivos"));
const ContaXReferencia = lazy(() => import('./pages/Configuracoes/ContaXReferencia'));

const RPALogins = lazy(() => import("./pages/Configuracoes/RPA/Logins"));
const RPAContas = lazy(() => import("./pages/Configuracoes/RPA/Contas"));

// Cadastros
const MonitorImportacao = lazy(() => import("./pages/Cadastros/MonitorImportacao"));
const MonitorImportacaoDetalhes = lazy(() => import("./pages/Cadastros/MonitorImportacao/Detalhes"));
const RpaLogsExecucao = lazy(() => import("./pages/Cadastros/RpaLogsExecucao"));
const RpaLogsExecucaoDetalhes = lazy(() => import("./pages/Cadastros/RpaLogsExecucao/Detalhes"));

// BTBm
const BTBm = lazy(() => import("./pages/BTBm"));
const Generico = lazy(() => import("./pages/BTBm/Generico"));

// default
const ModuloDefault = lazy(() => import("./pages/ModuloDefault"));

//salvando cookies no localstorage
const isAuthenticated = () => {
  const res = cookie.loadAll();

  if (res.authed) {
    localStorage.setItem("User", unescape(res.usuario));
    localStorage.setItem("Token", unescape(res.authed));
    localStorage.setItem("Email", unescape(res.email));
    localStorage.setItem("Data", moment().format());

    cookie.remove("authed", { path: "/" });
  }

  if (localStorage.getItem("Token")) {
    return true;
  } else return false;
};

// const PrivateRoute = (props) => {
//   const Component = props.component;

//   return !isAuthenticated(props) 
//     ? (<MapRoute exact name={Login} label={Login} path={""} render={(props) => <Login {...props} />}/>) 
//     : (<MapRoute exact name={props.name} label={props.label} path={props.path} render={(props) => <Component {...props}/>}/>)
// }

const PrivateRoute = ({ component: Component, ...rest }) => (
  <MapRoute {...rest} render={(props) => !isAuthenticated({ ...rest })
    ? <Login {...props} />
    : <Component {...props} />}
  />
);



export default function App() {
  return (
    <Fragment>
      <Router>
        <React.Suspense
          fallback={<LoadingSpin />}
        >
          <Mapping notFoundRedirect="/404">
            <Route exact path="/">
              {localStorage.getItem("Token") ? (
                <Redirect to={JSON.parse(localStorage.getItem("Homepath")) ?? "/dashboard"} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>

            <Grouping prefix="/login">
              <PrivateRoute
                exact
                name="Login"
                label="Login"
                path="/"
                component={Login}
              />
            </Grouping>

            <Grouping prefix="/modulo-default">
              <PrivateRoute
                exact
                name="Módulo default"
                label="Módulo default"
                path="/"
                component={() =>
                  <ModuloDefault
                    category="Módulo default"
                    subCategory="Módulo default" />
                }
              />
            </Grouping>

            <Grouping prefix="dashboard">
              <PrivateRoute
                exact
                name="Dashboard"
                label="Dashboard"
                path="/"
                component={NewDashboard}
              />
              <PrivateRoute
                exact
                name="Clientes"
                label={t`Detalhes Cliente`}
                path="/clientes"
                component={DashClientes}
              />

              <PrivateRoute
                exact
                name="Faturamento"
                label="Faturamento"
                path="/faturamento"
                component={FilaDeFaturamento}
              />
              <PrivateRoute
                exact
                name="Dashboard de ativos"
                label={t`Dashboard de ativos`}
                path="/ativos"
                component={DashboardAtivos}
              />
              <PrivateRoute
                exact
                name="Dashboard"
                label={t`Dashboard: Relatórios`}
                path="/detalhes-dashboard/:codCliente/:codDashboard"
                component={DetalhesDashboard}
              />
              <PrivateRoute
                exact
                name="Dashboard controle de contas"
                label={t`Controle de contas`}
                path="controlecontas"
                component={ControleContas}
              />
              <PrivateRoute
                exact
                name="Mapeamento de serviço"
                label={t`Mapeamento de serviços`}
                path="/servicos/:nomCliente/:codCliente"
                component={StepDeServico}
              />
              <PrivateRoute
                exact
                name="Novo Faturamento"
                label="Novo Faturamento"
                path="/novo-faturamento"
                component={NovoFaturamento}
              />
              <Grouping prefix="contas-com-titularidade">
                <PrivateRoute
                  exact
                  name="Titularidade"
                  label="Titularidade"
                  path="/:nomCliente"
                  component={ContasTitularidade}
                />
                <PrivateRoute
                  exact
                  name="Mapeamento de serviços"
                  label={t`Mapeamento de serviços`}
                  path="/step-de-servicos/:nomCliente/:codCliente"
                  component={StepDeServico}
                />
              </Grouping>
            </Grouping>

            <Grouping prefix="billing">
              <PrivateRoute
                exact
                name={t`PreFatura`}
                label={t`Pré-Fatura`}
                path="/prefatura"
                component={PreFatura}
              />
              <Grouping prefix="fatServicos">
                {/* <PrivateRoute
                  exact
                  name="Faturamento"
                  label="CNPJ por cliente/serviço"
                  path="/cliente-servico-cnpj"
                  component={ClienteServicoCNPJ}
                /> */}
                <PrivateRoute
                  exact
                  name="Faturamento"
                  label="Regras de faturamento"
                  path="/regra-faturamento"
                  component={RegraFaturamento}
                />
                <PrivateRoute
                  exact
                  name="Faturamento"
                  label="Catalogo tarifa"
                  path="/catalogo-tarifa"
                  component={CatalogoTarifa}
                />
                <PrivateRoute
                  exact
                  name="Faturamento"
                  label={t`Fatura detalhe serviço`}
                  path="/fatura-detalhe-servico"
                  component={FaturaDetalheServico}
                />
              </Grouping>
              <Grouping prefix="faturamento">
                <PrivateRoute
                  exact
                  path="manutencao"
                  name={t`Manutenção de preços (ST)`}
                  label={t`Manutenção de preços (ST)`}
                  component={Manutencao}
                />
                <PrivateRoute
                  exact
                  path="faturamento-automatico"
                  name={t`Faturamento automático (ST)`}
                  label={t`Faturamento automático (ST)`}
                  component={FaturamentoAutomatico}
                />
              </Grouping>

              <Grouping prefix="detalhamento">
                <Grouping prefix="ativos">
                  <PrivateRoute
                    exact
                    name="Arquivos"
                    label={t`Arquivos`}
                    path="/arquivos"
                    component={DetalhamentoAtivoArquivo}
                  />
                  <PrivateRoute
                    exact
                    name="Detalhamento"
                    label={t`Detalhamento`}
                    path="/detalhes"
                    component={DetalhamentoAtivos}
                  />
                  <PrivateRoute
                    exact
                    name=""
                    label={t`Detalhamento`}
                    path="/detalhes/:codAtivoDetalheArquivo"
                    component={DetalhamentoAtivos}
                  />
                </Grouping>
              </Grouping>
              <PrivateRoute
                exact
                name="Operadora"
                label={t`Operadora`}
                path="/operadora"
                component={Operadora}
              />
              <PrivateRoute
                exact
                name="Grupo de Serviços"
                label={t`Grupo de Serviços`}
                path="/grupo-de-servicos"
                component={GrupoDeServico}
              />
              <PrivateRoute
                exact
                name="Serviço Operadora"
                label={t`Serviço Operadora`}
                path="/servico-operadora"
                component={ServicoOperadora}
              />
              <PrivateRoute
                exact
                name="Perfil"
                label={t`Perfil`}
                path="/perfil-cliente"
                component={PerfilCliente}
              />
              <PrivateRoute
                exact
                name="Fatura Imposto"
                label={t`Fatura Imposto`}
                path="/fatura-imposto"
                component={FaturaImposto}
              />
              <PrivateRoute
                exact
                name="Fatura consumo"
                label={t`Fatura consumo`}
                path="/fatura-consumo"
                component={FaturaConsumo}
              />
            </Grouping>

            <Grouping prefix="financeiro">
              <Grouping prefix="contrato">
                <PrivateRoute
                  exact
                  name="Contrato"
                  label={t`Documento`}
                  path="/documento"
                  component={Contrato}
                />
                <PrivateRoute
                  exact
                  name="Detalhes do Documento"
                  label={t`Detalhes do Documento`}
                  path="/detalhe-contrato/:id"
                  component={DetalhesContrato}
                />
                <Grouping prefix="conta">
                  <PrivateRoute
                    exact
                    name="Conta"
                    label={t`Conta`}
                    path="/"
                    component={ContratoContas}
                  />
                  <PrivateRoute
                    exact
                    name="Detalhes da Conta"
                    label={t`Detalhes da Conta`}
                    path="/detalhe-conta/:id"
                    component={DetalhesConta}
                  />
                  <PrivateRoute
                    exact
                    name="Nova conta"
                    label={t`Cadastro de conta`}
                    path="/cadastro-conta"
                    component={CadastroConta}
                  />
                  <PrivateRoute
                    exact
                    name="Edição da Conta"
                    label={t`Edição da Conta`}
                    path="/cadastro-conta/:id"
                    component={CadastroConta}
                  />
                </Grouping>
                <Grouping prefix="fatura">
                  <PrivateRoute
                    exact
                    name="Fatura"
                    label={t`Fatura`}
                    path="/"
                    component={Fatura}
                  />
                  <PrivateRoute
                    exact
                    name="Detalhes da Fatura"
                    label={t`Detalhes da Fatura`}
                    path="/detalhe-fatura/:id"
                    component={DetalhesFatura}
                  />
                  <PrivateRoute
                    exact
                    name="Cadastro de fatura"
                    label={t`Cadastro de fatura`}
                    path="/cadastro-fatura"
                    component={CadastroFatura}
                  />
                  <PrivateRoute
                    exact
                    name="Edição de fatura"
                    label={t`Edição de fatura`}
                    path="/cadastro-fatura/:id"
                    component={CadastroFatura}
                  />
                </Grouping>
              </Grouping>
            </Grouping>

            <Grouping prefix="configs">
              <Grouping prefix="rpa">
                <PrivateRoute
                  exact
                  path="/logins"
                  name={t`Logins`}
                  label={t`Logins`}
                  component={RPALogins}
                />
                <PrivateRoute
                  exact
                  path="/logins/contas/:codOperadoraClienteLogin"
                  name={t`Contas`}
                  label={t`Contas`}
                  component={RPAContas}
                />
              </Grouping>
              <Grouping prefix="ativo">
                <PrivateRoute
                  exact
                  name="ativo-Permissionamentos"
                  label={t`Campos do ativo para ocultar`}
                  path="/permissionamento"
                  component={AtivoPermissionamento}
                />
                <PrivateRoute
                  exact
                  name="FiltroCampo"
                  label={t`Filtros pré-cadastrados`}
                  path="/filtro-campo"
                  component={FiltroCampoAtivos}
                />
              </Grouping>
              <Grouping prefix="tipo-ativo">
                <PrivateRoute
                  exact
                  name="tAtivo-Permissionamentos"
                  label={t`Tipos de ativo para ocultar`}
                  path="/permissionamento"
                  component={TipoAtivoPermissionamento}
                />
                <PrivateRoute
                  exact
                  name="Propriedades Tipo de Ativo"
                  label={t`Valores de propriedades 'tipo de ativo' para ocultar`}
                  path="/props/permissionamento"
                  component={PropsTipoAtivoPermissionamento}
                />
              </Grouping>
              <Grouping prefix="webhooks">
                <PrivateRoute
                  exact
                  name="Webhooks"
                  label="Webhooks"
                  path="/"
                  component={Webhooks}
                />
                <PrivateRoute
                  exact
                  name="Detalhes Webhook"
                  label={t`Detalhes Webhook`}
                  path="/:codWebhook"
                  component={WebhooksDetalhes}
                />
              </Grouping>
              <Grouping prefix={"conta"}>
                <PrivateRoute
                  exact
                  name="Conta x Referência"
                  label={t`Conta x Referência`}
                  path="/contaxreferencia"
                  component={ContaXReferencia}
                />
              </Grouping>
              <Grouping prefix="extensoes">
                <PrivateRoute
                  exact
                  name="Estoque mínimo"
                  label={t`Estoque mínimo`}
                  path="/estoque-minimo"
                  component={ExtensoesEstoqueMinimo}
                />
                <PrivateRoute
                  exact
                  name="Estoque mínimo - todos os ativos"
                  label={t`Estoque mínimo - Todos os ativos`}
                  path="/estoque-minimo-todos-ativos"
                  component={ExtensoesEstoqueMinimoTodosAtivos}
                />
              </Grouping>
            </Grouping>

            <Grouping prefix="inventario">
              <Grouping prefix="ativo">
                <PrivateRoute
                  exact
                  name="Ativo"
                  label={t`Ativo`}
                  path="/"
                  component={Ativo}
                />
                <PrivateRoute
                  exact
                  name="Criar Ativo"
                  label={t`Criar Ativo`}
                  path="/criar-ativo"
                  component={AtivoCriarEditar}
                />
                <PrivateRoute
                  exact
                  name="Detalhes do Ativo"
                  label={t`Detalhes do Ativo`}
                  path="/detalhes-inventario/:id"
                  component={DetalhesAtivo}
                />
                <PrivateRoute
                  exact
                  name="Editar Ativo"
                  label={t`Editar Ativo`}
                  path="/editar-ativo/:codAtivoParam"
                  component={AtivoCriarEditar}
                />
                <PrivateRoute
                  exact
                  name="Relacionamento Ativo x Ativo"
                  label="Relacionamento Ativo x Ativo"
                  path="/relacionamentos-ativos-x-ativos/:codCliente/:codAtivo"
                  component={RelacionamentosAtivo}
                />
              </Grouping>

              <Grouping prefix="tipo-ativo">
                {/* 230526 - Tela de tipo de ativo renomeada para "inventário" */}
                <PrivateRoute
                  exact
                  name="Tipo de Ativo"
                  label={t`Inventário`}
                  path="/"
                  component={TipoAtivo}
                />
                <PrivateRoute
                  exact
                  name="Criar Tipo de Ativo"
                  label={t`Criar Tipo de Ativo`}
                  path="/criar-tipo-ativo"
                  component={TipoAtivoCriarEditar2}
                />
                <PrivateRoute
                  exact
                  name="Editar Tipo de Ativo"
                  label={t`Editar Tipo de Ativo`}
                  path="/editar-tipo-ativo/:codTipoAtivo"
                  component={TipoAtivoCriarEditar2}
                />
              </Grouping>

              <Grouping prefix="usuarios">
                <PrivateRoute
                  exact
                  name="Usuarios"
                  label={t`Usuários`}
                  path="/"
                  component={ListaUsuarios}
                />

                <PrivateRoute
                  exact
                  name="Editar Usuário"
                  label={t`Editar Usuário`}
                  path="/cadastro-usuario/:codUsuario"
                  component={CadastroUsuario}
                />

                <PrivateRoute
                  exact
                  name="Cadastro do Usuário"
                  label={t`Cadastro do Usuário`}
                  path="/cadastro-usuario"
                  component={CadastroUsuario}
                />

                <PrivateRoute
                  exact
                  name="Detalhes Usuarios"
                  label={t`Detalhes Usuário`}
                  path="/detalhes-usuario/:codUsuario"
                  component={DetalhesUsuario}
                />
              </Grouping>

              <Grouping prefix="backoffice">
                <PrivateRoute
                  exact
                  name="Backoffice"
                  label="Backoffice"
                  path="/"
                  component={Backoffice}
                />

                <PrivateRoute
                  exact
                  name="Liberação de exceções"
                  label="Liberação de exceções"
                  path="/genericoexcecaoregra"
                  component={GenericoExcecaoRegra}
                />
              </Grouping>


            </Grouping>

            {/* <Grouping prefix="gerenciador-permissoes">
              <PrivateRoute
                exact
                name="Gereciamento de Permissões"
                label="Gereciamento de Permissões"
                path="/"
                component={GerenciadorPermissoes}
              />
            </Grouping> */}

            <Grouping prefix="relatorios">
              <PrivateRoute
                exact
                name="Relatórios"
                label={t`Relatórios`}
                path="/"
                component={Relatorios}
              />
              <PrivateRoute
                exact
                name="Criar Relatório"
                label="Criar Relatório"
                path="/novo-relatorio"
                component={NovoRelatorio}
              />
              <PrivateRoute
                exact
                name="Editar Relatório"
                label={t`Editar Relatório`}
                path="/editar-relatorio/:codRelatorio"
                component={EditarRelatorio}
              />
              <PrivateRoute
                exact
                name="Detalhes do Relatório"
                label={t`Detalhes do Relatório`}
                path="/detalhes-relatorio/:id"
                component={DetalhesRelatorio}
              />
            </Grouping>

            <Grouping prefix="catalogo">
              <PrivateRoute
                exact
                path="/"
                name="Catálogo"
                label="Catálogo"
                component={Catalogo}
              />
              <PrivateRoute
                exact
                path="/cadastrar-perfil"
                name="Cadastrar"
                label="Cadastrar"
                component={CadastroPerfil}
              />
              <PrivateRoute
                exact
                path="/editar-perfil/:codPerfil/:codCliente/:codOperadora"
                name="Editar Perfil"
                label="Editar Perfil"
                component={EditarPerfil}
              />
              <PrivateRoute
                exact
                path="/detalhes-perfil/:codPerfil/:codCliente/:codOperadora"
                name="Detalhes do Perfil"
                label="Detalhes do Perfil"
                component={DetalhesPerfil}
              />
            </Grouping>

            <Grouping prefix="cadastros">
              <PrivateRoute
                exact
                path="/monitor-importacao"
                name={t`Monitor de importação`}
                label={t`Monitor de importação`}
                component={MonitorImportacao}
              />
              <PrivateRoute
                exact
                path="/rpa-logs-execucao"
                name={t`Rpa - Logs de execução`}
                label={t`Rpa - Logs de execução`}
                component={RpaLogsExecucao}
              />
              <PrivateRoute
                exact
                path="/monitor-importacao/detalhes/:codImportacaoGenericaArquivo"
                name="Detalhes"
                label="Detalhes"
                component={MonitorImportacaoDetalhes}
              />
              <PrivateRoute
                exact
                path="rpa-logs-execucao/detalhes/:dscProtocoloExecucao"
                name={t`Detalhes`}
                label={t`Detalhes`}
                component={RpaLogsExecucaoDetalhes}
              />
            </Grouping>

            <Grouping prefix="btbm">
              <PrivateRoute
                path="*"
                name={t`BTBm`}
                label={t`BTBm`}
                component={BTBm}
              />

            </Grouping>
            <Grouping prefix="btbm-v2">
              <PrivateRoute
                exact
                path="/generico"
                name={t`Genérico`}
                label={t`Genérico`}
                component={Generico}
              />
            </Grouping>

            {/* <Grouping prefix="webhooks">
              <PrivateRoute
                exact
                name="Webhooks"
                label="Webhooks"
                path="/"
                component={Webhooks}
              />
              <PrivateRoute
                exact
                name="Detalhes Webhook"
                label="Detalhes Webhook"
                path="/:codWebhook"
                component={WebhooksDetalhes}
              />
            </Grouping> */}
          </Mapping>
        </React.Suspense>
      </Router>
    </Fragment>
  );
}
